import request from '@/utils/request'
// 获取班级圈
export function Getmoments(query) {
  return request({
    url: '/mobile/moments/index',
    method: 'get',
    params: query
  })
}

// 添加班级圈点赞
export function addlike(id) {
  return request({
    url: '/mobile/activity/addLike',
    method: 'post',
    data: { moments_id: id }
  })
}

// 取消点赞
export function dellike(id) {
  return request({
    url: '/mobile/activity/delLike',
    method: 'post',
    data: { moments_id: id }
  })
}

// 删除班级圈
export function momentsdel(id) {
  return request({
    url: '/mobile/moments/del',
    method: 'post',
    data: { ids: id}
  })
}