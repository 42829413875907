<template>
  <div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <img class="topimg" src="../../assets/img/lookorr.png" alt="" @click="lookme" v-if="this.page.ismy == '1'"  />
        <img class="topimg" src="../../assets/img/Slice 39@2x.png" alt="" @click="lookme" v-if="this.page.ismy == '0'"  />

        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >

        <div class="modul" v-for="(item,index) in list" :key="index">
          <div class="disfix">
            <div class="avatar">
              <img v-if="item.photo" :src="oss_url + item.photo">
              <img v-else src="../../assets/img/nohead.png" alt="">
            </div>
            <div class="name">
              <p style="font-weight: bold;font-size: 1rem;">{{ item.user_name }}</p>
              <p style="margin-top: -0.6rem; font-size:.8rem; color: #666666">
                {{ item.create_time }}
              </p>
            </div>
          </div>
          <div class="content"><span>{{item.content}}</span></div>
          <div class="image"> 
            <div v-if="item.files.length == 1 ">
              
              <div v-for="(url,index) in item.files" :key="index">
                <van-image
                  v-if="url.type == 1"
                  class="Imgbox4"
                  @click="getImg(item.files,index)"
                  fit="cover"
                  :src="oss_url + url.file_url"
                />
                <div v-if="url.type == 2">
                  <video :src="oss_url + url.file_url" controls="true"></video>
                </div>
              </div>

              

            </div>
            <div v-if="item.files.length == 2 ">
              <van-image
                class="Imgbox4"
                @click="getImg(item.files,index)"
                v-for="(url,index) in item.files"
                :key="index"
                fit="cover"
                :src="oss_url + url.file_url"
              />
            </div>
            <div v-if="item.files.length == 4 || item.files.length == 3 ">
              <van-image
                class="Imgbox3"
                @click="getImg(item.files,index)"
                v-for="(url,index) in item.files"
                :key="index"
                fit="cover"
                :src="oss_url + url.file_url"
              />
            </div>
            <div v-if="item.files.length == 9 || item.files.length == 7 || item.files.length == 8 || item.files.length == 6 || item.files.length == 5">
              <van-image
                class="Imgbox9"
                fit="cover"
                @click="getImg(item.files,index)"
                v-for="(url,index) in item.files"
                :key="index" 
                :src="oss_url + url.file_url"
              />
            </div>
          </div>
          <div class="love">
            <div @click="delmoments(item.id,index) ">
                <div v-if="item.is_my == true">
                  <img style="width:1rem;margin-bottom: -.2rem;" src="@/assets/img/delete-icon.png"><span style="font-size:.9rem;color: #999999;">删除</span>
                </div>
            </div>
            <div v-if="item.my_likes == true">
              <img style="width:4.5vw;margin-bottom: -.2rem;" src="@/assets/img/icon_love.png" @click="likecount(item,index)"><span style="font-size:.9rem;color: #999999;">{{ item.like_count }}</span>
            </div>
            <div v-else >
              <img style="width:4.5vw;margin-bottom: -.2rem;" src="@/assets/img/icon_nolove.png" @click="likecount(item,index)"><span style="font-size:.9rem;color: #999999;">{{ item.like_count }}</span>
            </div>
          </div>
        </div>

        </van-list>

      </van-pull-refresh>
      <div v-if="this.user_type !== 'student'">
        <img class="add" @click="open_btn" src="@/assets/img/add.png">
        <van-overlay :show="show" :lock-scroll= "false">
          <div class="activity"><img  src="@/assets/img/activity_button.png" @click="goClasscircle"><br><span>班级圈</span></div>
          <!-- <div class="circle_button"><img src="@/assets/img/circle_button.png" @click="gosend"><br><span>活动</span></div> -->
        </van-overlay>
      </div>
      <!-- <van-loading color="#1989fa" class="loading" size="100" v-show="loading" /> -->
      <div style="height: 13vw;"></div>
      <foot></foot>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { ImagePreview } from 'vant';
import Foot from "@/components/Foot.vue";
import { Getmoments, momentsdel, addlike, dellike } from '@/api/Class_circle.js'
import {getUserType} from "../../utils/user";

export default {
  components: { Foot },
  data() {
    return {
      value:'1',
      button_show: false,
      show: false,
      list: [],
      file:[],
      imghead:[
        { imgUrl:require('@/assets/img/actva-head.png'), },
      ],
      images:[],
      ismyUrl: require('@/assets/img/Slice 39@2x.png'),
      // loading: false,
      page:{
        ismy: 0,
        page: 1,
        limit: 8,
      },
      total: null,
      loading: false,
      finished: false,
      isLoading: false,
      oss_url: process.env.VUE_APP_OSS_URL,
      user_type: ''
    }
    ;
  },
  created(){
    this.user_type = getUserType();
    this.getlist()
  },
  mounted() {

     window.addEventListener("scroll", this.scrolling);

  },
  methods:{
    getlist(){
      Getmoments(this.page).then(res => {
        this.list = [...this.list, ...res.data.rows]
        this.total = res.data.total
      })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.page.page++
         this.getlist()

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true;
        }
      }, 1000);
    },
    // 下拉刷新
    onRefresh(){
      this.page.page = 1
      
      Getmoments(this.page).then(res => {
        this.list = res.data.rows
        setTimeout(()=>{
        this.isLoading = false
      },1000)
      })
    },
    // 删除班级圈
    delmoments(id,index){
      momentsdel(id).then(res => {
        if(res.code == 200){
          Toast.success('删除成功');
          // this.$set(this.list[index],'','')
          this.$delete(this.list,index)
        }
      })
    },
    // 点赞
    likecount(row,index){
      console.log(row);
      console.log(index);
      if(row.my_likes == false){
        addlike(row.id).then(res => {
          this.$set(this.list[index], "my_likes", true) // 更新点赞
          this.$set(this.list[index], "like_count", +1) // 更新点赞数量
          console.log(this.list);
        })
      }else{
        dellike(row.id).then(res => {
          this.$set(this.list[index], "my_likes", false)// 更新点赞
          this.$set(this.list[index], "like_count",this.list[index].like_count -1)// 更新点赞数量
        })
      }
    },
    //查看图片
    getImg(images,index){
      let urlarr=[]
      images.forEach(item => {
        urlarr.push( this.oss_url + item.file_url)
      });
      ImagePreview({
        images: urlarr,
        showIndex:true,
        loop:false,
        startPosition:index
      })
    },
    // 查看我发的
    lookme(){
      if(this.page.ismy == '0'){
        this.page.ismy = '1'
      }else{
        this.page.ismy = '0'
      }
      this.page.page = 1
      this.finished = false
      this.list = []
      Getmoments(this.page).then(res => {
        this.list = [...this.list, ...res.data.rows]
        this.total = res.data.total
      })
    },
    open_btn(){
      this.show = !this.show
    },
    //前往活动
    gosend(){
      this.$router.push('/send')
    },
    // 前往班级圈
    goClasscircle(){
      this.$router.push('/Classcircle')
    },
},
};
</script>

<style lang="less" scoped>
.active{
  color: red;
  
}
.topimg {
  margin-top: 1rem;
  width: 8rem;
}
.modul {
  padding: 1rem;
  border-bottom: 1px solid #b0b0b04d;
  margin-bottom: .5rem;
}
/* 头像 */
.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50px;
  overflow: hidden;
  img{
    width: 100%;
  }
}
//
.disfix {
  display: flex;
}
.name {
  margin: -.5rem 0 0 .7rem;
}
//文字内容
.content {
  color: #666666;
  margin-left: 14vw;
  margin-top: .3rem;
  width: 76vw;
  font-size: 3vw;
  white-space:normal;
  word-break:break-all;
  word-wrap:break-word; 
}
.content span{
  font-size: 4vw;
}
//图片内容
.image {
  padding-top: .5rem;
  margin-left: 14vw
} 
/////////////////////////////////////////////
ul{
    padding:0px;
    display: flex;
    /* justify-content: flex-end; */ //用于设置或检索弹性盒子元素在主轴（横轴）方向上的对齐方式。
    flex-wrap: wrap; //换行   
}
li{
    list-style: none;
    width:30%;
    padding-top: 1%;
}
/* 除了每行最后的元素,其他都留右外边距 (100-32*3)/2*/
li:not(:nth-child(3n)){
    margin-right: 1%; 
}
/* 当ul只有唯一的li时 */
li:only-child{
    width: 100%;
    height: 100%;
}
/* 当只有唯一一张图时 自适应宽高*/
li:only-child img{
    max-height: 220px;
    width: auto;
    max-width: 220px;
    height: auto
}
/* 有四张图时  even偶数  odd奇数 */
li:nth-last-child(4):first-child ~ li:nth-child(even){
    margin-right: 10%;
}
li:nth-last-child(4):first-child ~ li:nth-child(odd){
    margin-right: 2%;
}

.love{
  display: flex;
  margin-left: 14vw;
  justify-content: space-between;
  padding-top: 2vh;
  padding-right: 3vw;
}
.add{
    width: 3rem;
    z-index: 999;
    position: fixed;
    bottom: 9%;
    right: 3%;
}
.activity{
  position: fixed;
  bottom: 15%;
  right: 23%;
  text-align: center;
}
.activity img{
  width: 2.5rem;
  z-index: 999;
}
.activity span{
  color: white;
}
.circle_button{
  position: fixed;
  bottom: 19%;
  right: 7%;
  text-align: center;
}
.circle_button img{
  width: 2.5rem;
  z-index: 999;
}
.circle_button span{
  color: white;
}
.Imgbox3{
  width: 25vw;
  height: 25vw;
  margin-right: 1vw;
}
.Imgbox4{
  width: 36vw;
  height: 36vw;
  margin-right: 1vw;
}
.Imgbox9{
  width: 25vw;
  height: 25vw;
  margin-right: 1vw;
}
.loading{
  position: fixed;
  top: 45%;
  left: 40%;
}
video{
  width: 90%;
}
</style>
